
<template>
    <div class="pager">
        <div class="nav">
            <van-nav-bar :title="$t('subscribe.title')" left-arrow @click-left="$router.go(-1)" />
        </div>

        <div class="box">
            <div class="box_header">
                <van-tabs style="width: 100%;" v-model="status" @click="onSwitch" color="#f0b82d" title-active-color="#f0b82d">
                    <van-tab :title="$t('subscribe.tabs.0')" />
                    <van-tab :title="$t('subscribe.tabs.1')" />
                </van-tabs>
            </div>
            <!-- :finished-text="items.length == 0 ? '' : $t('common.nomore')" -->
            <van-list v-model="loading" :finished="finished" 
                :loading-text="$t('jia-zai-zhong')" @load="onLoad">
                <div class="box_list">
                    <div class="cell" v-for="(item, key) in items" :key="key">
                        <div v-if="status == 0">
                            <div class="top">
                                <img :src="item.icon" />
                                <span class="name">{{ item.name }}</span>
                                <van-button type="primary" size="small" @click="onRedeemPop(item)">{{ $t('subscribe.redeem')
                                }}</van-button>
                            </div>
                            <div class="bottom">
                                <div>
                                    <span>{{ $t('subscribe.money') }}</span>
                                    <span>{{ item.money }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('financial.list.items.1') }}</span>
                                    <span>{{ item.day_rate }}%</span>
                                </div>
                            </div>
                            <div class="bottom">
                                <div style="align-items: flex-start;">
                                    <span>{{ $t('subscribe.time') }}</span>
                                    <span>{{ item.createtime | dateformat }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('subscribe.yield') }}</span>
                                    <span>{{ item.income }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('subscribe.DaysRemaining') }}</span>
                                    <span>{{ item.days }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="top">
                                <img :src="item.icon" />
                                <span class="name">{{ item.name }}</span>
                                <span class="time">{{ item.createtime | dateformat }}</span>
                            </div>

                            <div class="bottom">
                                <div style="align-items: flex-start;">
                                    <span>{{ $t('subscribe.money') }}</span>
                                    <span>{{ item.money }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('financial.list.items.1') }}</span>
                                    <span>{{ item.day_rate }}%</span>
                                </div>
                                <div>
                                    <span v-if="item.status == 2">{{ $t('subscribe.liquidated damages') }}</span>
                                    <span v-if="item.status == 2">{{ item.d_money }}{{ " " + $t('fuhao') }}</span>

                                    <span v-if="item.status == 1">{{ $t('subscribe.total revenue') }}</span>
                                    <span v-if="item.status == 1">{{ item.income }}{{ " " + $t('fuhao') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
            <!-- :description="$t('common.nomore')" -->
            <van-empty v-if="items.length == 0" />


        </div>
        <van-dialog v-model:show="redeem.isShow" :showConfirmButton="false">
            <div class="redeem">
                <div class="tips">
                    <span>{{ $t('webAssets.tips') }}</span>
                </div>
                <div class="content">
                    <span>{{ $t('subscribe.tips.0') }}</span>
                    <span>{{ ((redeem.item.wy_rate / 100) * redeem.item.days  * redeem.item.money).toFixed(2) }}</span>
                    <span>{{ $t('subscribe.tips.1') }}</span>
                </div>
                <div class="buttons">
                    <van-button type="primary" size="small" @click="redeem.isShow = false">
                        {{ $t('common.cancel') }}
                    </van-button>
                    <div style="width: 10px;"></div>
                    <van-button type="primary" size="small" @click="onRedeem">
                        {{ $t('common.confirm') }}
                    </van-button>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            redeem: {
                isShow: false,
                item: {}
            },
            items: [],
            status: 0,
            page: 0,
            loading: false,
            finished: false,
        }
    },

    methods: {

        onLoad() {
            this.page++;

            this.$http.get("/home/home/wmpOrderList?status=" + (this.status + 1) + "&page=" + this.page).then(res => {

                this.loading = false;
                if (res.data.code == 200) {
                    if (res.data.data.list.length == 0) {
                        this.finished = true;
                    }
                    if (this.page == 1) {
                        this.items = res.data.data.list;
                    } else {
                        this.items.push(...res.data.data.list);
                    }
                }
            }).catch(err => {
                this.loading = false;
                this.finished = true;
            })
        },
        onSwitch(e) {
            this.page = 0;
            this.onLoad();
        },
        onRedeemPop(item) { // 赎回
            this.redeem.item = item
            this.redeem.isShow = true
        },
        onRedeem() {
            this.redeem.isShow = false
            this.$http.post("/home/home/wmpBack", {
                id: this.redeem.item.id
            }).then(res => {
                if (res.data.code === 200) {
                    this.page = 0;
                    this.onLoad();
                    this.$toast(this.$t("common.actionok"));

                } else if (res.data.code === 4001) {
                    this.$toast(this.getlang('余额不足', localStorage.getItem('lang')));
                }else {
                    this.$toast(this.$t("common.actionfail"));
                }
            }).catch(err => {
                this.$toast(this.$t("common.actionfail"));
            })



        }

    }


}

</script>

<style scoped lang="less">
.pager {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #E3E6EA;
    overflow-y: scroll;
}

.nav {
    position: fixed;
    width: 100vw;
    font-size: 1.12rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
    z-index: 999;
}

.box {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 46px;

    .box_header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100vw;
        padding: 10px 0;

        /deep/.van-tabs__nav {
            background-color: #ffffff00;
        }
    }

    .box_list {
        width: 100vw;
        display: flex;
        flex-direction: column;
        padding: 0 16px;

        .cell {
            margin-top: 15px;
            padding: 18px 12px;
            background: #fff;
            border-radius: 5px;

            .top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 28px;
                    height: 28px;
                }

                .name {
                    color: #333;
                    font-size: 16px;
                    font-weight: 500;
                    flex: 1;
                    margin: 0 14px;
                    text-align: left;
                }

                .time {
                    color: #777;
                    font-size: 12px;
                    font-weight: 400;
                }



                /deep/.van-button--primary {

                    border-radius: 5px;
                    background: #f0b82d;
                    border: none;
                }


            }




            .bottom {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    span:first-child {
                        color: #777;
                        font-size: 12px;
                        font-weight: 400;
                    }

                    span:last-child {
                        margin-top: 6px;
                        color: #363636;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
            }
        }

    }
}

.redeem {
    padding: 20px 15px;

    .tips {
        color: #333;
        font-size: 14px;
        font-weight: 600;
    }

    .content {
        text-align: left;
        margin-top: 15px;
        color: #333;
        font-size: 12px;
        font-weight: 400;
    }

    .buttons {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        /deep/.van-button--primary {
            flex: 1;
            border-radius: 5px;
            background: #f0b82d;
            border: none;
        }

        /deep/.van-button--primary:first-child {
            border-radius: 5px;
            background: #E3E6EA;
            border: none;
            color: #363636;
        }
    }


}
</style>